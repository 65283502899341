import React from 'react';

// ---------- Error Page ----------
 
const Error = () => {
    return (
       <div className="Error">
          <p>Error: Page does not exist!</p>
       </div>
    );
}
 
export default Error;